<template>
  <!-- Modal -->
  <div
    :class="!show ? 'modal' : 'modal d-block'"
    id="toggleMyModal"
    data-backdrop="static"
    tabindex="-1"
    role="dialog"
    aria-labelledby="toggleMyModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ data && data.title }}
          </h5>
          <button
            type="button"
            class="btn-close"
            @click="handleClose()"
          ></button>
        </div>
        <div class="modal-body">
          <div
            class="d-flex h-100 w-100 justify-content-center align-items-center"
            v-if="data && data.url"
          >
            <iframe
              :src="data && data.url"
              class="w-100 h-100 border-0"
              frameborder="0"
              v-if="data && data.mimeType.includes('pdf')"
            />
            <img :src="data && data.url" height="100%" v-else />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal-pdf",
  props: ["data", "show", "close"],
  methods: {
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>