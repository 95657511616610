import { render, staticRenderFns } from "./UserPermohonanKatam.vue?vue&type=template&id=73e83d6d&"
import script from "./UserPermohonanKatam.vue?vue&type=script&lang=js&"
export * from "./UserPermohonanKatam.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports