<template>
  <div style="min-height: calc(100vh - 360px)">
    <div class="">
      <h5>Syarat-syarat Permohonan PIHTAS</h5>
      <p class="ms-3 mb-0">
        a. Calon merupakan PEKTA yang mempunyai KATAM yang sedang berkuatkuasa.
        <br />
        b. Mempunyai Sijil Pembimbing Ibadat Haji (SPIH). <br />
        c. Aktif menyampaikan kursus haji TH di peringkat negeri dan daerah
        sekurang-kurangnya 3 tahun selepas ditauliahkan sebagai PEKTA.<br />
        d. Tidak dilantik sebagai PIHTAS dalam tempoh 3 tahun berturut.<br />
        e. Mengurus sendiri pelepasan majikan.<br />
        f. Tidak terlibat membawa Jemaah haji syarikat swasta sekiranya
        dilantik.<br />
        g. Sihat tubuh badan dan umur tidak melebihi 60 tahun. <br />
        h. Sanggup bertugas melebihi 60 hari. <br />
        i. Berakhlak mulia dan tidak merokok. <br />
        j. Memohon keizinan suami/wali (bagi pemohon Wanita)
      </p>
    </div>
    <div class="my-4" />
    <div>
      <button type="button" @click="handleClick" class="btn btn-primary">
        Klik di Sini Untuk Memohon
      </button>
    </div>
  </div>
</template>

<script>
import SessionManager from '../../utils/SessionManager'
import { mapGetters } from "vuex";

export default {
  methods: {
    handleClick() {
      if(this.currentUserToken){
        let encryptedToken = SessionManager.setSimpleEncryptData(this.currentUserToken)
        window.open(`${process.env.VUE_APP_PEKTA_URL}/verify_auth?token=${encryptedToken}&redirect=myprofile`);
      }
      else 
        window.open(`${process.env.VUE_APP_PEKTA_URL}/myprofile`);
    },
  },
  computed: {
    ...mapGetters(["currentUserToken"]),
  },
};
</script>