<template>
  <div style="min-height: calc(100vh - 360px)">
    <div class="">
      <h5>Syarat-syarat Permohonan KATAM</h5>
      <p class="ms-3">
        a. Memohon bagi pembaharuan KATAM pada setiap tahun. <br />
        b. Menyertai dan lulus Kelas Intensif Tafaqquh Haji dan Umrah (KITHU)
        anjuran TH. <br />
        c. Menyertai dan lulus Muzakarah Haji Peringkat Kebangsaan <br />
        d. Menyertai dan lulus Kursus Tauliah Haji Umrah (KUTAHU)
      </p>
    </div>
    <div class="my-4" />
    <div>
      <button type="button" @click="handleClick" class="btn btn-primary">
        Klik di Sini Untuk Memohon
      </button>
    </div>
  </div>
</template>

<script>
import SessionManager from '../../utils/SessionManager'
import { mapGetters } from "vuex";

export default {
  methods: {
    handleClick() {
      if(this.currentUserToken){
        let encryptedToken = SessionManager.setSimpleEncryptData(this.currentUserToken)
        window.open(`${process.env.VUE_APP_PEKTA_URL}/verify_auth?token=${encryptedToken}&redirect=myprofile`);
      }
      else 
        window.open(`${process.env.VUE_APP_PEKTA_URL}/myprofile`);
    },
  },
  computed: {
    ...mapGetters(["currentUserToken"]),
  },
};
</script>