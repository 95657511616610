<template>
  <div style="min-height: calc(100vh - 360px)">
    <div
      class="row align-items-start mb-2"
      v-for="key in Object.keys(models)"
      :key="`form-password-${key}`"
    >
      <template v-if="isEditMode || !models[key].editModeOnly">
        <div class="col-12 col-md col-label">
          <label
            :for="`form-input-${key}`"
            class="col-form-label col-form-label-sm"
          >
            {{ models[key].label }}
          </label>
        </div>
        <div class="col-12 col-md col-form">
          <p
            :id="`form-input-${key}`"
            v-if="!isEditMode"
            class="
              form-control form-control-sm
              bg-transparent
              text-muted
              shadow-sm
              mb-2
            "
          >
            ********
          </p>
          <input
            :id="`form-input-${key}`"
            :placeholder="models[key].placeholder"
            :type="models[key].type"
            v-else
            class="
              form-control form-control-sm
              bg-transparent
              text-muted
              shadow-sm
              mb-2
            "
            v-model="models[key].model"
          />
          <div v-if="isEditMode && key == 'newPassword'" class="passwordSugesstion">
            <span class="d-flex ">
              Kata laluan anda perlu mengikut kriteria berikut :
            </span> 
            <div class="d-flex ps-3 flex-column kriterias">
              <div class="d-flex point" :class="{ticked: passwordCriterias.point1 }">
                <span v-if="passwordCriterias.point1" class="point-w-icon"><i class="fa fa-check-circle ms-2"></i></span>
                <span v-else class="point-w-bullet">&#8226;</span>
                <span>Melebihi lapan (8) karekter</span>
              </div>
              <div class="d-flex point" :class="{ticked: passwordCriterias.point2 }">
                <span v-if="passwordCriterias.point2" class="point-w-icon"><i class="fa fa-check-circle ms-2"></i></span>
                <span v-else class="point-w-bullet">&#8226;</span>
                <span>Kombinasi huruf kecil & huruf besar</span>
              </div>
              <div class="d-flex point" :class="{ticked: passwordCriterias.point3 }">
                <span v-if="passwordCriterias.point3" class="point-w-icon"><i class="fa fa-check-circle ms-2"></i></span>
                <span v-else class="point-w-bullet">&#8226;</span>
                <span>Sekurang-kurangnya satu (1) karekter istimewa</span>
              </div>
              <div class="d-flex point" :class="{ticked: passwordCriterias.point4 }">
                <span v-if="passwordCriterias.point4" class="point-w-icon"><i class="fa fa-check-circle ms-2"></i></span>
                <span v-else class="point-w-bullet">&#8226;</span>
                <span>Sekurang-kurangnya satu (1) nombor</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="col-12 mb-4 pt-4 border-bottom"></div>
    <div class="text-end">
      <button
        class="btn btn-sm btn-light bg-white shadow-sm border"
        v-if="!isEditMode"
        @click="updatePassword()"
      >
        Kemaskini Kata Laluan
      </button>
      <template v-else>
        <button
          class="btn btn-sm btn-light bg-white shadow-sm border me-3"
          @click="cancelEdit()"
          :disabled="isSaving"
        >
          Batal
        </button>
        <button
          class="btn btn-sm btn-success shadow-sm border text-light"
          @click="save()"
          :disabled="isSaving"
          v-if="isSaveReady"
        >
          <template v-if="isSaving">
            <span
              class="spinner-border spinner-border-sm text-light me-2"
              role="status"
              aria-hidden="true"
            ></span>
            Mengemaskini...
          </template>
          <span v-else>Simpan</span>
        </button>
        <button v-else
          class="btn btn-sm btn-secondary shadow-sm border text-light"
          disabled=true
        >
          Simpan
        </button>
      </template>
    </div>
    <loading-spinner v-if="isSaving" />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import API from "@/utils/API";
import { mapActions, mapGetters } from "vuex";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  components: { LoadingSpinner },
  data() {
    return {
      isSaving: false,
      isEditMode: false,
      passwordCriterias: {
        point1: false,
        point2: false,
        point3: false,
        point4: false
      },
      isSaveReady: false,
      models: {
        currentPassword: {
          label: "Kata Laluan Sekarang",
          type: "password",
          placeholder: "Masukkan kata laluan anda",
          model: null,
          editModeOnly: false,
        },
        newPassword: {
          label: "Kata Laluan Baru",
          type: "password",
          placeholder: "Masukkan kata laluan baru anda",
          model: null,
          editModeOnly: true,
        },
        confirmPassword: {
          label: "Sahkan Kata Laluan Baru",
          type: "password",
          placeholder: "Sahkan kata laluan anda",
          model: null,
          editModeOnly: true,
        },
      },
    };
  },
  watch: {
    models : {
      handler(_val) {
        //handle the passwordSuggestion for new Password
        if(_val.newPassword.model)
          this.passwordSuggestionValidator(_val.newPassword.model)

        this.checkIfReadyToBeSaved()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    ...mapActions(["authLogout"]),
    passwordSuggestionValidator(_password){
      if(_password.length >= 8)
        this.passwordCriterias.point1 = true
      else this.passwordCriterias.point1 = false

      // if(_password.match(/([a-z])([A-Z])/))
      if(_password.match(/^(?=.*[a-z])(?=.*[A-Z])/))
        this.passwordCriterias.point2 = true
      else this.passwordCriterias.point2 = false

      if(_password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/))
        this.passwordCriterias.point3 = true
      else this.passwordCriterias.point3 = false

      if(_password.match(/\d/))
        this.passwordCriterias.point4 = true
      else this.passwordCriterias.point4 = false
    },
    checkIfReadyToBeSaved(){
      this.isSaveReady = false //default

      if(this.models.currentPassword.model !== null && this.models.newPassword.model !== null && this.models.confirmPassword.model !==null){
        if(this.passwordCriterias.point1 == true && this.passwordCriterias.point2 == true && this.passwordCriterias.point3 == true && this.passwordCriterias.point4 == true) {
          if(this.models.newPassword.model == this.models.confirmPassword.model)
            return this.isSaveReady = true
        }
      }
    },
    cancelEdit() {
      this.isEditMode = false;

      Object.key(this.models).forEach((key) => {
        this.models[key].model = null;
      });
    },
    async updatePassword() {
      this.isEditMode = true;

      const appBody = document.getElementById("app");

      if (appBody) {
        appBody.scrollTo(0, 0);
      }
    },
    async save() {
      Swal.fire({
        title: "<h5>Kemaskini kata laluan?</h5>",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Batal",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isSaving = true;

          if (
            this.models.newPassword.model !== this.models.confirmPassword.model
          ) {
            this.isSaving = false;

            return Swal.fire({
              icon: "error",
              title: "<h5 class='mb-0'>Kata Laluan Baru Tidak Sepadan</h5>",
              confirmButtonText: "Tutup",
              customClass: {
                confirmButton: "btn btn-sm btn-primary",
              },
              buttonsStyling: false,
            });
          } else {
            try {
              await API.post("auth/local", {
                identifier: this.currentUser.email,
                password: this.models.currentPassword.model,
              });

              await API.put(`users/${this.currentUser.id}`, {
                password: this.models.newPassword.model,
              });

              return Swal.fire({
                icon: "success",
                title: "<h5 class='mb-0'>Kata Laluan Telah Dikemaskini</h5>",
                html: "Sila daftar masuk semula menggunakan kata laluan yang baru",
                confirmButtonText: "Tutup",
                customClass: {
                  confirmButton: "btn btn-sm btn-primary",
                },
                buttonsStyling: false,
              }).then(() => {
                this.authLogout();
              });
            } catch (error) {
              this.isSaving = false;

              return Swal.fire({
                icon: "error",
                title: "<h5 class='mb-0'>Kata Laluan Sekarang Tidak Sah</h5>",
                confirmButtonText: "Tutup",
                customClass: {
                  confirmButton: "btn btn-sm btn-primary",
                },
                buttonsStyling: false,
              });
            }
          }
        }
      });
    },
  },
};
</script>


<style lang="scss">
@import "./../../assets/scss/color.scss";
@import "./../../assets/scss/main.scss";

.passwordSugesstion {
  font-size: 13.5px;
  color: #a2a2a2;
  padding: 5px 0px 10px 0px;

  .kriterias {
    .point {
      &.ticked {
        color: $green;
      }

      .point-w-icon {
        margin-left: -10px;
        margin-right: 5px;
      }

      .point-w-bullet {
        margin-left: 2px;
        margin-right: 9px;
      }
    }
  }
}

</style>