<template>
  <div
    class="bg-dark bg-opacity-75"
    :class="!show ? 'modal' : 'modal d-block'"
    id="toggleMyModal"
    data-backdrop="static"
    tabindex="-1"
    role="dialog"
    aria-labelledby="toggleMyModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            Tambah Sijil &amp; Kelayakan
          </h5>
          <button
            type="button"
            class="btn-close"
            @click="handleClose()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label for="pektaQualificationType" class="form-label">
              Tahap
            </label>
            <!-- <input
              type="text"
              class="form-control form-control-sm"
              id="pektaQualificationType"
              v-model="models.type"
            /> -->
            <select
              class="form-select btn-sm"
              aria-label="Default select example"
              id="pektaQualificationType"
              v-model="models.type"
            >
              <option disabled :value="null">Pilih Tahap</option>
              <option
                v-for="(item, index) in type_levels"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div class="mb-3">
            <label for="pektaQualificationLevel" class="form-label">
              Kursus
            </label>
            <input
              type="text"
              class="form-control form-control-sm"
              id="pektaQualificationLevel"
              v-model="models.level"
            />
          </div>
          <div>
            <label for="pektaQualificationCert" class="form-label">
              Sijil Berkaitan
            </label>
            <input
              type="file"
              class="form-control form-control-sm"
              id="pektaQualificationCert"
              @input="validateFileInput('pektaQualificationCert', 'models', 'cert_file', $event.target.files[0])"
            />
          </div>
        </div>
        <div
          class="modal-footer d-flex justify-content-between align-items-center"
        >
          <button class="btn btn-sm btn-light border" @click="handleClose()">
            Batal
          </button>
          <button class="btn btn-sm btn-primary" @click="submit()">
            Simpan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/utils/API";

export default {
  name: "modal-pekta-qualification-form",
  props: ["show", "close"],
  data() {
    return {
      models: {
        type: null,
        level: null,
        cert_file: null,
      },
      type_levels: ['Doktor Falsafah', 'Ijazah Sarjana', 'Ijazah Sarjana Muda', 'Diploma', 'Sijil', 'Lain-lain']
    };
  },
  watch: {
    show: {
      handler() {
        if (this.show) {
          Object.keys(this.models).forEach((key) => {
            this.models[key] = null;
          });

          const pektaQualificationCert = document.getElementById(
            "pektaQualificationCert"
          );

          pektaQualificationCert.value = null;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    validateFileInput(_fileID, _filedataparent, _filedatachild, _filetarget){
      this[_filedataparent][_filedatachild] = _filetarget
      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.pdf)$/i;

      if(!allowedExtensions.exec(this[_filedataparent][_filedatachild].name)){
        alert('Please upload file having extensions .jpeg/.jpg/.png/.gif/.pdf only.')
        this[_filedataparent][_filedatachild] = null
        document.getElementById(_fileID).value = ''
      }
    },
    async submit() {
      let data =
        this.models.type || this.models.level
          ? {
              type: this.models.type,
              level: this.models.level,
            }
          : null;

      if (data && this.models.cert_file) {
        this.$emit("toggleLoading", true);

        const formData = new FormData();

        formData.append(
          "files",
          this.models.cert_file,
          this.models.cert_file.name
        );

        const uploadResponse = await API.postForm("upload", formData);

        if (uploadResponse.data && uploadResponse.data.length > 0) {
          data["cert_file"] = uploadResponse.data[0].id;
        }

        this.$emit("toggleLoading", false);
      }

      this.$emit("save", data);
    },
  },
};
</script>