<template>
  <div style="min-height: calc(100vh - 360px)">
    <div class="row align-items-start mb-2">
      <div class="col-12 col-md col-label">
        <label
          for="form-input-profile-image"
          class="col-form-label col-form-label-sm"
        >
          {{ profile_image.label }}
        </label>
      </div>
      <div class="col-12 col-md col-form position-relative">
        <template v-if="!isEditMode || profile_image.model">
          <div
            class="profile-image"
            v-if="profile_image.model && profile_image.model.url"
          >
            <img :src="profile_image.model.url" class="img-thumbnail" />
            <button
              class="
                btn btn-sm btn-danger
                position-absolute
                btn-clear
                end-0
                top-0
              "
              v-if="isEditMode"
              @click="clearPreviewImage()"
            >
              <i class="fas fa-trash-alt" />
            </button>
          </div>
          <p
            id="form-input-profile-image"
            class="
              form-control form-control-sm
              bg-transparent
              text-muted
              fst-italic
              border-0
              mb-2
            "
            v-else
          >
            Tiada gambar ditemui
          </p>
        </template>
        <div class="position-relative" v-else>
          <input
            class="form-control form-control-sm"
            id="form-input-profile-image"
            type="file"
            @input="profileImageSelected"
          />
          <button
            class="
              btn btn-sm btn-clear
              position-absolute
              end-0
              top-0
              text-danger
              h-100
            "
            v-if="profile_image.preview"
            @click="clearPreviewImage()"
          >
            x
          </button>
        </div>
      </div>
    </div>
    <div class="mb-4 pt-2 border-bottom"></div>
    <div
      class="row align-items-start mb-2"
      v-for="userKey in Object.keys(userModels)"
      :key="`form-user-${userKey}`"
    >
      <div class="col-12 col-md col-label">
        <label
          :for="`form-input-${userKey}`"
          class="col-form-label col-form-label-sm"
        >
          {{ userModels[userKey].label }}
        </label>
      </div>
      <div class="col-12 col-md col-form">
        <p
          v-if="!isEditMode || !userModels[userKey].isEditable"
          :id="`form-input-${userKey}`"
          class="form-control form-control-sm bg-transparent text-muted mb-2"
        >
          {{ userModels[userKey].model || "&nbsp;" }}
        </p>
        <template v-else>
          <input
            :id="`form-input-${userKey}`"
            class="form-control form-control-sm bg-white shadow-sm mb-2"
            :class="{ 'is-invalid': invalidFields.includes(userKey) }"
            :key="`form-user-${userKey}`"
            :placeholder="userModels[userKey].label"
            v-model="userModels[userKey].model"
          />
          <p class="note text-muted px-2" v-if="userModels[userKey].note">
            {{ userModels[userKey].note }}
          </p>
        </template>
      </div>
    </div>
    <div 
      class="row align-items-start mb-2"
      v-for="petugasKey in Object.keys(petugasModels)"
      :key="`form-user-${petugasKey}`"
      v-if="currentUser && currentUser.role.type == 'petugas_haji'"
      >
      <div class="col-12 col-md col-label">
        <label
          :for="`form-input-${petugasKey}`"
          class="col-form-label col-form-label-sm"
        >
          {{ petugasModels[petugasKey].label }}
        </label>
      </div>
      <div class="col-12 col-md col-form">
        <p
          :id="`form-input-${petugasKey}`"
          class="form-control form-control-sm bg-transparent text-muted mb-2"
        >
          {{ petugasModels[petugasKey].model || "&nbsp;" }}
        </p>
      </div>
    </div>
    <template
      v-if="currentUser && currentUser.role && currentUser.role.name == 'Pekta'"
    >
      <div class="mb-4 pt-2 border-bottom"></div>
      <div
        class="row align-items-start mb-2"
        v-for="pektaKey in Object.keys(pektaModels)"
        :key="`form-user-${pektaKey}`"
      >
        <div class="col-12 col-md col-label">
          <label
            :for="`form-input-${pektaKey}`"
            class="col-form-label col-form-label-sm"
          >
            {{ pektaModels[pektaKey].label }}
          </label>
        </div>
        <div class="col-12 col-md col-form">
          <div v-if="pektaKey == 'pekta_qualification'">
            <p
              v-if="pektaModels[pektaKey].model.length == 0"
              class="
                form-control form-control-sm
                bg-transparent
                border-0
                text-muted
                fst-italic
                mb-0
              "
            >
              Tiada sijil ditemui
            </p>
            <div
              class="input-group input-group-sm mb-2 flex-nowrap"
              v-for="(qualification, index) in pektaModels[pektaKey].model"
              :key="`qualification.${index}`"
            >
              <span class="input-group-text fw-light text-muted">
                {{ index + 1 }}
              </span>
              <p
                class="
                  form-control form-control-sm
                  shadow-sm
                  bg-transparent
                  text-muted
                  mb-0
                "
              >
                {{ qualification.type }}
                <span v-if="qualification.level">
                  ({{ qualification.level }})
                </span>
              </p>
              <button
                class="border ms-2 px-3 rounded shadow-sm"
                :class="{
                  invisible: !qualification.cert_file,
                }"
                v-if="!isEditMode"
                @click="viewFile(qualification)"
              >
                <i class="fas fa-file-alt" />
              </button>
              <button
                class="border ms-2 px-3 rounded btn-danger shadow-sm"
                v-else
                @click="removeQualification(index)"
              >
                <small><i class="fas fa-trash-alt" /></small>
              </button>
            </div>
            <div class="text-end" v-if="isEditMode">
              <button
                class="btn-sm btn-secondary py-1 px-3 text-light"
                @click="showFormModal = true"
              >
                <i class="fas fa-plus me-2" />
                Tambah
              </button>
            </div>
          </div>
          <template v-else>
            <p
              v-if="!isEditMode || !pektaModels[pektaKey].isEditable"
              :id="`form-input-${pektaKey}`"
              class="
                form-control form-control-sm
                bg-transparent
                text-muted
                mb-2
              "
            >
              {{ pektaModels[pektaKey].model || "&nbsp;" }}
            </p>
            <div v-else>
              <select
                v-if="pektaKey == 'occupation'"
                class="form-select form-select-sm shadow-sm"
                aria-label="Default select example"
                :key="`form-input-${pektaKey}`"
                v-model="pektaModels[pektaKey].model"
              >
                <option :value="null" disabled>-- Pilih pekerjaan anda</option>
                <option v-for="item in occupationLists" :key="item">
                  {{ item }}
                </option>
              </select>
              <input
                v-else
                :id="`form-input-${pektaKey}`"
                class="form-control form-control-sm bg-white shadow-sm mb-2"
                :class="{ 'is-invalid': invalidFields.includes(pektaKey) }"
                :key="`form-pekta-${pektaKey}`"
                :placeholder="pektaModels[pektaKey].label"
                v-model="pektaModels[pektaKey].model"
              />
            </div>
          </template>
        </div>
      </div>
    </template>
    <div class="mb-4 pt-2 border-bottom"></div>
    <div class="row align-items-center mb-2">
      <div class="col-12 col-md col-label align-self-start">
        <label
          :for="`form-input-address`"
          class="col-form-label col-form-label-sm pb-0"
        >
          Alamat Anda
        </label>
        <p class="text-muted mb-0 fw-light">
          Berdasarkan alamat tempat tinggal sekarang
        </p>
      </div>
      <div class="col-12 col-md col-form">
        <div class="row g-2">
          <template v-for="addressKey in Object.keys(addressModels)">
            <div
              :class="`col-12 col-md-${addressModels[addressKey].size} lh-sm`"
              :key="`form-column-${addressKey}`"
            >
              <template v-if="isEditMode">
                <input
                  v-if="addressModels[addressKey].type == 'text'"
                  :id="`form-input-${addressKey}`"
                  class="form-control form-control-sm bg-white shadow-sm mb-2"
                  :class="{ 'is-invalid': invalidFields.includes(addressKey) }"
                  :key="`form-address-${addressKey}`"
                  :placeholder="addressModels[addressKey].label"
                  v-model="addressModels[addressKey].model"
                  @input="validateAddress(addressKey)"
                />
                <select
                  v-else-if="addressModels[addressKey].type == 'select'"
                  class="form-select form-select-sm shadow-sm"
                  aria-label="Default select example"
                  :key="`form-address-${addressKey}`"
                  v-model="addressModels[addressKey].model"
                >
                  <option :value="null" disabled>-- Pilih negeri anda</option>
                  <option
                    v-for="option in addressModels[addressKey].options"
                    :key="`${addressKey}-option-${option}`"
                  >
                    {{ option }}
                  </option>
                </select>
                <span
                  class="text-danger form-text fs-11"
                  v-if="isEditMode && invalidFields.includes(addressKey)"
                >
                  Sila masukkan poskod dengan format yang betul
                </span>
              </template>
              <p
                v-else
                :id="`form-input-${addressKey}`"
                class="
                  form-control form-control-sm
                  bg-transparent
                  text-muted
                  mb-2
                "
              >
                {{ addressModels[addressKey].model }}
              </p>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="mb-4 pt-2 border-bottom"></div>
    <div class="row align-items-center mb-2">
      <div class="col-12 col-md col-label align-self-start">
        <label
          :for="`form-input-address`"
          class="col-form-label col-form-label-sm pb-0"
        >
          Telefon
        </label>
      </div>
      <div class="col-12 col-md col-form">
        <div class="row g-2">
          <div
            v-for="phoneKey in Object.keys(phoneModels)"
            :class="`col-12 lh-sm`"
            :key="`form-column-${phoneKey}`"
          >
            <div class="input-group input-group-sm mb-2 flex-nowrap">
              <span
                class="input-group-text phone-group-text fw-light text-muted"
              >
                {{ phoneModels[phoneKey].label }}
              </span>
              <template v-if="isEditMode">
                <input
                  :id="`form-input-${phoneKey}`"
                  class="form-control bg-white shadow-sm"
                  :class="{ 'is-invalid': invalidFields.includes(phoneKey) }"
                  :key="`form-phone-${phoneKey}`"
                  type="tel"
                  @input="validateTel(phoneKey, phoneModels[phoneKey].model)"
                  v-model="phoneModels[phoneKey].model"
                />
              </template>
              <p
                v-else
                :id="`form-input-${phoneKey}`"
                class="form-control bg-transparent text-muted mb-0"
              >
                {{ phoneModels[phoneKey].model }}
              </p>
            </div>
            <span
              class="text-danger form-text fs-11"
              v-if="isEditMode && invalidFields.includes(phoneKey)"
            >
              Sila masukkan nombor telefon dengan format yang betul
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4 pt-2 border-bottom"></div>
    <div class="text-end">
      <button
        class="btn btn-sm btn-light bg-white shadow-sm border"
        @click="editProfile()"
        v-if="!isEditMode"
      >
        Kemaskini Profil
      </button>
      <template v-else>
        <button
          class="btn btn-sm btn-light bg-white shadow-sm border me-3"
          @click="cancelEdit()"
          :disabled="isSaving"
        >
          Batal
        </button>
        <button
          class="btn btn-sm btn-success shadow-sm border text-light"
          @click="save()"
          :disabled="isSaving || invalidFields.length > 0"
        >
          <template v-if="isSaving">
            <span
              class="spinner-border spinner-border-sm text-light me-2"
              role="status"
              aria-hidden="true"
            ></span>
            Mengemaskini...
          </template>
          <span v-else>Simpan</span>
        </button>
      </template>
    </div>
    <pdf-modal
      :data="selectedFile"
      :show="selectedFile.url"
      @close="closeViewFile()"
    />
    <pekta-form-modal
      :show="showFormModal"
      @toggleLoading="isSaving = $event"
      @close="showFormModal = false"
      @save="addPektaQualification"
    />
    <loading-spinner v-if="isSaving" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Helper from "@/utils/helper";
import Swal from "sweetalert2";
import API from "../../utils/API";
import LoadingSpinner from "@/components/LoadingSpinner";
import PektaFormModal from "@/components/ModalPektaQualificationForm";
import PdfModal from "@/components/ModalPDF";

export default {
  components: { LoadingSpinner, PdfModal, PektaFormModal },
  data() {
    return {
      showFormModal: false,
      isEditMode: false,
      isSaving: false,
      selectedFile: {
        url: null,
        title: null,
        mimeType: null,
      },
      pektaApplicationId: null,
      pektaModels: {
        occupation: {
          label: "Pekerjaan",
          type: "text",
          model: null,
          isEditable: true,
        },
        employer_name: {
          label: "Majikan",
          type: "text",
          model: null,
          isEditable: true,
        },
        pekta_qualification: {
          label: "Sijil & Kelayakan",
          type: "component",
          model: [],
          isEditable: true,
        },
      },
      petugasModels: {
        pma_no: {
          label: "No. PMA Anda",
          type: "text",
          model: null,
          isEditable: false,
        },
        petugas_role: {
          label: "Kategori Petugas",
          type: "text",
          model: null,
          isEditable: false,
        },
        petugas_macca_position: {
          label: "Jawatan Anda di Tanah Suci",
          type: "text",
          model: null,
          isEditable: false,
        }
      },
      profile_image: {
        label: "Gambar Profil",
        model: null,
        preview: null,
        isEditable: true,
      },
      userModels: {
        email: {
          label: "Alamat E-mel",
          type: "text",
          model: null,
          note: "E-mel hanya boleh dikemaskini sekali sahaja. Sila pastikan alamat e-mel yang anda masukkan adalah betul.",
          isEditable: true,
        },
        registration_number: {
          label: "No. Bakal Haji Anda",
          type: "text",
          model: null,
          isEditable: false,
        },
        full_name: {
          label: "Nama Penuh",
          type: "text",
          model: null,
          isEditable: false,
        },
        id_no: {
          label: "No. KP Anda",
          type: "text",
          model: null,
          isEditable: false,
        },
      },
      phoneModels: {
        phone_mobile: {
          label: "Telefon Bimbit",
          model: null,
          isEditable: true,
        },
        phone_home: {
          label: "Rumah",
          model: null,
          isEditable: true,
        },
        phone_office: {
          label: "Pejabat",
          model: null,
        },
      },
      addressModels: {
        address_line: {
          label: "Nama Jalan",
          model: null,
          size: 12,
          type: "text",
          isEditable: true,
        },
        district: {
          label: "Bandar",
          model: null,
          size: 12,
          type: "text",
          isEditable: true,
        },
        postcode: {
          label: "Poskod",
          model: null,
          size: 6,
          type: "text",
          isEditable: true,
          validation: this.validatePostcode,
        },
        state: {
          label: "Negeri",
          model: null,
          size: 6,
          type: "select",
          isEditable: true,
          options: [
            "Johor",
            "Kedah",
            "Kelantan",
            "Melaka",
            "Negeri Sembilan",
            "Pahang",
            "Perak",
            "Perlis",
            "Pulau Pinang",
            "Sabah",
            "Sarawak",
            "Selangor",
            "Terengganu",
            "W.P Kuala Lumpur",
            "W.P Labuan",
            "W.P Putrajaya",
          ],
        },
      },
      occupationLists: ["Kerajaan", "Swasta", "Bekerja Sendiri", "Bersara"],
      invalidFields: [],
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  watch: {
    currentUser: {
      handler() {
        if (
          this.currentUser &&
          this.currentUser.role &&
          this.currentUser.role.name == "Administrator"
        ) {
          delete this.userModels.registration_number;
        } else if (
          this.currentUser &&
          this.currentUser.role &&
          this.currentUser.role.name == "Pekta"
        ) {
          this.userModels.registration_number.label = "No. PEKTA Anda";

          this.getPektaInfo();
        } else if (
          this.currentUser &&
          this.currentUser.role &&
          this.currentUser.role.name == "Petugas Haji"
        ) {
          // delete this.userModels.registration_number
          this.userModels.registration_number.label = "No. Petugas Anda";
          this.getPetugasInfo();
        } else {
          this.userModels.registration_number.label = "No. Bakal Haji Anda";
        }

        this.populateUserDetails();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    removeQualification(index) {
      this.pektaModels.pekta_qualification.model.splice(index, 1);
    },
    addPektaQualification(e) {
      if (e) {
        const item = { ...e };

        this.pektaModels.pekta_qualification.model.push(item);

        this.showFormModal = false;
      }
    },
    viewFile(file) {
      this.selectedFile.title = file.type;

      if (file.cert_file) {
        this.selectedFile.url = file.cert_file.attributes.url;
        this.selectedFile.mimeType = file.cert_file.attributes.mime;
      } else {
        this.selectedFile.url = null;
        this.selectedFile.mimeType = null;
      }
    },
    closeViewFile() {
      this.selectedFile.url = null;
      this.selectedFile.title = null;
      this.selectedFile.mimeType = null;
    },
    profileImageSelected(e) {
      const files = e.target.files;

      if (files.length > 0) {
        this.profile_image.preview = files[0];
      } else {
        this.profile_image.preview = null;
      }

      this.validateFileInput(e.target.id, 'profile_image', 'preview')
    },
    validateFileInput(_fileID, _filedataparent, _filedatachild){
      let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.pdf)$/i;

      if(!allowedExtensions.exec(this[_filedataparent][_filedatachild].name)){
        alert('Please upload file having extensions .jpeg/.jpg/.png/.gif/.pdf only.')
        this[_filedataparent][_filedatachild] = null
        document.getElementById(_fileID).value = ''
      }
    },
    clearPreviewImage() {
      this.profile_image.model = null;

      this.profile_image.preview = null;

      const fileInput = document.getElementById("form-input-profile-image");

      if (fileInput) {
        fileInput.value = "";
      }
    },
    editProfile() {
      this.isEditMode = true;

      const appBody = document.getElementById("app");

      if (appBody) {
        appBody.scrollTo(0, 0);
      }
    },
    ...mapActions(["updateUser"]),
    updateProfileImage() {
      return new Promise(async (resolve) => {
        const formData = new FormData();

        formData.append(
          "files",
          this.profile_image.preview,
          this.profile_image.preview.name
        );

        const uploadResponse = await API.postForm("upload", formData);

        if (uploadResponse.data && uploadResponse.data.length > 0) {
          return resolve(uploadResponse.data[0].id);
        } else {
          return resolve(null);
        }
      });
    },
    async updatePektaInfo() {
      if (!this.pektaApplicationId) return;

      const body = {
        data: {},
      };

      Object.keys(this.pektaModels).forEach((key) => {
        if (key !== "pekta_qualification") {
          body.data[key] = this.pektaModels[key].model;
          body.data[key] = Helper.validateAndRemoveUnwantedTags(body.data[key])
        } else {
          body.data[key] = this.pektaModels[key].model.map((pq) => {
            const q = {
              type: pq.type,
              level: pq.level,
            };

            if (pq.id) {
              q["id"] = pq.id;
            }

            if (pq.cert_file) {
              q["cert_file"] = pq.cert_file;
            }

            return q;
          });
        }

      });

      await API.put(`pekta-applications/${this.pektaApplicationId}`, body);

      await this.getPektaInfo();

      return;
    },
    save() {
      Swal.fire({
        icon: "question",
        title: "<h5 class='mb-0'>Kemaskini Butiran Peribadi</h5>",
        html: "Adakah anda pasti?",
        showCancelButton: true,
        confirmButtonText: "Pasti",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-sm btn-primary",
          cancelButton: "btn btn-sm btn-outline-secondary",
        },
        buttonsStyling: false,
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isEditMode = false;

          this.isSaving = true;

          const userObject = Object.assign(
            { ...this.userModels },
            { ...this.phoneModels },
            { ...this.addressModels }
          );

          let body = {};

          Object.keys(userObject).forEach((key) => {
            body[key] = userObject[key].model;
            body[key] = Helper.validateAndRemoveUnwantedTags(body[key])
          });

          if (this.currentUser.profile_image && !this.profile_image.model) {
            body["profile_image"] = null;
          }

          if (this.profile_image.preview) {
            const profile_image_id = await this.updateProfileImage();

            if (profile_image_id) {
              body["profile_image"] = profile_image_id;
            }
          }

          API.put(`users/${this.currentUser.id}`, body)
            .then(async () => {
              await this.updatePektaInfo();

              const updatedUser = await API.get(
                `users/${this.currentUser.id}?populate[0]=role&populate[1]=user_addresses&populate[2]=profile_image`
              );

              await this.updateUser(updatedUser.data);

              Swal.fire({
                icon: "success",
                title:
                  "<h5 class='mb-0'>Kemaskini Butiran Peribadi Berjaya</h5>",
                confirmButtonText: "Tutup",
                customClass: {
                  confirmButton: "btn btn-sm btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((err) => {
              console.error(err);

              this.displayUpdateError();
            })
            .finally(() => {
              this.isSaving = false;
            });
        }
      });
    },
    async getPektaInfo() {
      const { data } = await API.get(
        `pektas?filters[user]=${this.currentUser.id}&populate[pekta_applications][populate]=pekta_qualification.cert_file`
      );

      if (data.data && data.data.length > 0) {
        data.data.sort((a, b) => {
          return b.id - a.id;
        });

        const dataNested = data.data[0].attributes.pekta_applications;

        const pekta_applications = dataNested
          ? dataNested.data[0].attributes
          : {};

        this.pektaApplicationId = dataNested ? dataNested.data[0].id : null;

        Object.keys(this.pektaModels).forEach((key) => {
          if (pekta_applications[key]) {
            if (key !== "pekta_qualification") {
              this.pektaModels[key].model = pekta_applications[key];
            } else {
              this.pektaModels[key].model = pekta_applications[key].map(
                (pa) => {
                  return {
                    id: pa.id,
                    type: pa.type,
                    level: pa.level,
                    cert_file: pa.cert_file.data ? pa.cert_file.data : null,
                  };
                }
              );
            }
          }
        });
      }
    },
    async getPetugasInfo(){
      const { data } = await API.get(
        `petugas-hajis?filters[user]=${this.currentUser.id}`
      );

      if (data.data && data.data.length > 0) {
        data.data.sort((a, b) => {
          return b.id - a.id;
        });

        const petugasData = data.data[0]
        this.petugasModels.pma_no.model = petugasData.attributes.pma_no
        this.petugasModels.petugas_role.model = petugasData.attributes.petugas_role
        this.petugasModels.petugas_macca_position.model = petugasData.attributes.petugas_macca_position

        this.userModels.registration_number.model = this.userModels.id_no.model? this.userModels.id_no.model + this.currentUser.id : '-'
      }

    },
    displayUpdateError() {
      Swal.fire({
        icon: "error",
        title: "<h5 class='mb-0'>Kemaskini Butiran Peribadi Gagal</h5>",
        confirmButtonText: "Tutup",
        customClass: {
          confirmButton: "btn btn-sm btn-primary",
        },
        buttonsStyling: false,
      });
    },
    validateAddress(key) {
      if (this.addressModels[key].validation) {
        this.addressModels[key].validation(key, this.addressModels[key].model);
      }
    },
    validateTel(key, value) {
      if (Helper.validateTel(value)) {
        this.invalidFields = this.invalidFields.filter((field) => field != key);
      } else {
        if (!this.invalidFields.includes(key)) {
          this.invalidFields.push(key);
        }
      }
    },
    validatePostcode(key, value) {
      if (key && value) {
        if (Helper.validatePostcode(value)) {
          this.invalidFields = this.invalidFields.filter(
            (field) => field != key
          );
        } else {
          if (!this.invalidFields.includes(key)) {
            this.invalidFields.push(key);
          }
        }
      }
    },
    cancelEdit() {
      this.populateUserDetails();

      if (
        this.currentUser &&
        this.currentUser.role &&
        this.currentUser.role.name == "Pekta"
      ) {
        this.getPektaInfo();
      }

      this.isEditMode = false;
    },
    populateUserDetails() {
      if (this.currentUser.profile_image) {
        this.profile_image.model = this.currentUser.profile_image;
      }

      Object.keys(this.userModels).forEach((key) => {
        if (key == "email") {
          if (
            this.currentUser &&
            this.currentUser.email &&
            [
              `${this.currentUser.registration_number}@tabunghaji.gov.my`,
              `${this.currentUser.id_no}@tabunghaji.gov.my`,
            ].includes(this.currentUser.email)
          ) {
            this.userModels[key].isEditable = true;

            this.userModels[key].model = null;
          } else {
            this.userModels[key].isEditable = false;

            this.userModels[key].model = this.currentUser[key];
          }
        } else {
          if (this.currentUser && this.currentUser[key]) {
            this.userModels[key].model = this.currentUser[key];
          } else {
            this.userModels[key].model = null;
          }
        }
      });

      Object.keys(this.phoneModels).forEach((key) => {
        if (this.currentUser && this.currentUser[key]) {
          if(key == 'phone_mobile')
            this.phoneModels[key].model = '0' + this.currentUser[key];
          else 
            this.phoneModels[key].model = this.currentUser[key];
        } else {
          this.phoneModels[key].model = null;
        }
      });

      Object.keys(this.addressModels).forEach((key) => {
        if (this.currentUser && this.currentUser[key]) {
          this.addressModels[key].model = this.currentUser[key];
        } else {
          this.addressModels[key].model = null;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-image {
  width: 150px;
  height: auto;

  img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}

.note {
  font-size: 0.8rem !important;
  line-height: 1.1;
  margin-top: -0.25rem;
}

.btn-clear {
  padding: 0 0.5rem 0 0.5rem !important;
}
</style>