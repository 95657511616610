<template>
  <div class="container mt-3">
    <nav aria-label="breadcrumb" class="py-2">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="cursor: pointer">
          <router-link to="/" class="text-black">
            <i class="fas fa-home" />
          </router-link>
        </li>
        <li class="breadcrumb-item active">Profil</li>
      </ol>
    </nav>
    <div class="pb-3">
      <ul class="nav border-bottom">
        <li
          class="nav-item"
          v-for="tab in tabsArr"
          :key="`tab-${!tab.route ? 'details' : tab.route}`"
        >
          <router-link
            class="nav-link small"
            :to="{ params: { tab: tab.route } }"
          >
            {{ tab.label }}
          </router-link>
        </li>
      </ul>
    </div>
    <div class="pt-3 py-5">
      <component
        :is="`user-${!$route.params.tab ? 'details' : $route.params.tab}`"
      />
    </div>
  </div>
</template>

<script>
import UserDetails from "@/components/user-profile/Details";
import UserPassword from "@/components/user-profile/Password";
import UserPermohonanPihtas from "@/components/user-profile/UserPermohonanPihtas";
import UserPermohonanKatam from "@/components/user-profile/UserPermohonanKatam";
import { mapGetters } from "vuex";

export default {
  components: {
    UserDetails,
    UserPassword,
    UserPermohonanPihtas,
    UserPermohonanKatam
  },
  data() {
    return {
      tabs: [
        {
          label: "Butiran Peribadi",
          route: "details",
        },
        {
          label: "Kata Laluan",
          route: "password",
        },
        {
          label: "Permohonan PIHTAS",
          route: "permohonan-pihtas",
        },
        {
          label: "Permohonan KATAM",
          route: "permohonan-katam",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    tabsArr() {
      return this.currentUser.role.name != "Pekta"
        ? this.tabs.filter((x) => x.route != "permohonan")
        : this.tabs;
    },
  },
  created(){
    if(this.currentUser && this.currentUser.role && this.currentUser.role.name !== 'Pekta'){
      this.tabs = [
        {
          label: "Butiran Peribadi",
          route: "details",
        },
        {
          label: "Kata Laluan",
          route: "password",
        },
      ]
    }
  },
  beforeMount() {
    if (
      !this.$route.params.tab ||
      !this.tabsArr.map((tab) => tab.route).includes(this.$route.params.tab)
    ) {
      this.$router.replace({
        params: {
          tab: this.tabsArr[0].route,
        },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.nav-link {
  color: #667085;
  margin-right: 1rem;

  &.router-link-exact-active {
    color: #4ca022;

    border-bottom: 2px solid #4ca022;
  }
}

::v-deep .col-label {
  margin-bottom: 0.5rem;
}

::v-deep .phone-group-text {
  // width: 90px;
  // width: 120px;
  width: 145px;
}

@media (min-width: 768px) {
  ::v-deep .col-label {
    flex-basis: 30%;
    flex-grow: 0;
  }

  ::v-deep .col-form {
    max-width: 460px;
  }
}

::v-deep .form-control::placeholder {
  font-size: 0.8rem !important;
  opacity: 0.6;
}
</style>